export const uxprojects = [
    {
        "id" : 1,
        "title" : "dhyana-meditation-app",
        "name" : "Dhyana - Meditation App",
        "desc" : "Designed an application which will help users relax with meditation, no matter where they would be.",
        "main-image" : "ux/meditation_app.png",
        "background-color": "#FFDE9A",
        "image-alt" : "Meditation App Main Image",
        "status" : "Read More",
        "height": "100%",
        "width" : "100%",
        "order-image" : "1",
        "order-text" : "0",
    },

    {
        "id" : 2,
        "title" : "classroom-app",
        "name" : "Classroom - Concept App",
        "desc" : "Designed an application which would enable students to track their classes, assignments and deadlines with ease.",
        "main-image" : "ux/classroom_app.png",
        "image-alt" : "Classroom App Main Image",
        "background-color" : "#F0F5FF",
        "status" : "Read More",
        "height": "100%",
        "width" : "90%",
        "order-image" : "0",
        "order-text" : "1"
    },

    {
        "id" : 3,
        "title" : "one-stop-delivery",
        "name" : "One Stop Delivery",
        "desc" : "Designed a food delivery application with a smooth flow from checking out menus till delivery at the door. Developed in association with Bleeding Edge Technologies.",
        "main-image" : "ux/one_stop_delivery.png",
        "image-alt" : "One Stop Main Image",
        "background-color" : "#FF9F9F",
        "status" : "In Progress",
        "height": "100%",
        "width" : "100%",
        "order-image" : "1",
        "order-text" : "0"
    },

    {
        "id" : 4,
        "title" : "flight-delay",
        "name" : "Flight Delay",
        "desc" : "Designed a flow which would assist users for a scenario of a flight delay. This comprises of handling users' emotions and guiding them to actions to overcome the delay.",
        "main-image" : "ux/flight-delay.png",
        "image-alt" : "Flight Delay Main Image",
        "background-color" : "#FFF3D2",
        "status" : "In Progress",
        "height": "100%",
        "width" : "70%",
        "order-image" : "0",
        "order-text" : "1"
    },

    // {
    //     "id" : 5,
    //     "title" : "panel-design",
    //     "name" : "TV Remote Panel Design",
    //     "desc" : "Designed a television remote panel, which assists people of all ages to use a remote with ease.",
    //     "main-image" : "ux/one_stop_delivery.png",
    //     "image-alt" : "One Stop Main Image",
    //     "background-color" : "#efefef",
    //     "status" : "In Progress",
    //     "height": "100%",
    //     "width" : "100%",
    //     "order-image" : "1",
    //     "order-text" : "0"
    // }
    
];