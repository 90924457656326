import React from 'react';
import '../navbar/navbar.css';
import { Link as NormalLink } from 'react-router-dom';

const Navbar = () => {

  return (
    <React.Fragment>
      <div className='new-navbar-container'>
        <div className='new-navbar'>
          <NormalLink to="/"><div className='brand-logo'><img src='logo.png' alt="Brand Logo" height="30rem" width="30rem" /></div></NormalLink>
          <NormalLink to="/about" className='new-navbar-text nunito-sans'>About</NormalLink>
          <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/14AX7JANTzRrV1GWnfzGRcl6XuTH8GXXf/view?usp=sharing" className='new-navbar-text nunito-sans'>Resume</a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar;