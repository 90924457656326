import React from 'react';
import './contact.css';
import {APIKEY} from '../../data/APIKEY';
import { useEffect, useState } from 'react';

const Contact = () => {
  const list_of_colors = ['stage-one', 'stage-two', 'stage-three', 'stage-four', 'stage-three', 'stage-two'];
  const [textColorIndex, setTextColorIndex] = useState(0);
  // Changes the color
  useEffect(() => {
  setTimeout(() => {
    if(textColorIndex === 5){
      setTextColorIndex(0);
    }
    else{
      setTextColorIndex(textColorIndex + 1);
    }
  }, 1000);
}, [textColorIndex]);


  return (
    <React.Fragment>
        <div className='title-text text-light-gray clash' id="contact">Let's work together!</div>
        <div className='contact-container'>
            <div className='contact-content nunito-sans text-medium-gray'>
              Let's bring your next BIG idea to life. Send a message or shoot an <a href="mailto:romaxakrajeev@gmail.com" className='email-link'>email</a>.
            </div>
            <div className='contact-form'>
                <form name="contact" className="nunito-sans" action="https://api.web3forms.com/submit" method="POST">
                    <input type="hidden" name="access_key" value={APIKEY} />
                    <div className='form-group'>
                        <label htmlFor='email'>Your Name</label>
                        <br />
                        <div className="input-field">
                            <input type="text" name="name" id="name" placeholder="Enter your Name" required />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor='email'>Your Email</label>
                        <br />
                        <div className="input-field">
                            <input type="email" name="email" id="email" placeholder="Enter your Email" required />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor='email'>Your Message</label>
                        <br />
                        <div className="input-field">
                            <textarea type="text" name="message" id="message" placeholder="Enter your Message" rows="5" required />
                        </div>
                    </div>
                    <input type="hidden" name="redirect" value="https://web3forms.com/success" />
                    <div className='form-group contact-button'>
                        <button className={`${list_of_colors[textColorIndex]} text-darker-gray no-border nunito-sans`}>Send me a Message !</button>
                    </div>
                </form>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Contact;