import React from 'react';
import './about.css';

const About = () => {

  return (
    <React.Fragment>
      <div className='breadcrumbs'>
        <div className='breadcrumb-previous nunito-sans text-medium-gray'><a href="/">Home</a>&nbsp;/&nbsp;</div>
        <div className='breadcrumb-active nunito-sans text-golden-yellow'>About Me</div>
      </div>
      <div className='title-text-case clash text-light-gray'>About Me</div>
      <div className='about-me-container'>
        <div className='about-me-grid'>
          <div className='about-me-content text-medium-gray nunito-sans'>
           <p>I'm a User Experience Designer working with emerging start-ups and brands create simple yet engaging user experiences for their products since 2019.</p>
            <p>Having graduated as a Computer Engineer in 2021, I also love solving problems through code. I try to develop some of my designs as actual, usable products too. Here are some of my projects on <a className='text-light-gray' target="_blank" href="https://github.com/romaxrajeev" rel="noreferrer">Github</a>.</p>
            <p>I have also worked extensively in Graphic Design - making logos, merchandise, posters, banners for personal, collegiate and professional projects. The designs made for my college helped in wide social reach with more admission enquiries and other colleges opting for similar designs for promotion.</p>
          </div>
          <div className='about-me-image'>
            <img src='me.jpg' height="80%" width="100%" alt='Romax Rajeev' />
          </div>
        </div>
      </div>
      <div className='skill-container'>
        <div className='title-text-about clash text-light-gray'>My Skills</div>
        <div className='skill-grid'>
          <div className='skill-section nunito-sans'>
            <div className='skill-top-image'><img src='skills/experience.png' height="50%" width="100%" alt='Icon for User Experience' /></div>
            <div className='skill-title text-light-gray'>Experience Design</div>
            <div className='skills text-medium-gray'>Product Design, Experience Design, Mobile Design, User Research, Visual Design and Prototyping.</div>
          </div>
          <div className='skill-section nunito-sans'>
            <div className='skill-top-image'><img src='skills/tools.png' height="50%" width="100%" alt='Icon for Design Tools' /></div>
            <div className='skill-title text-light-gray'>Design Tools</div>
            <div className='skills text-medium-gray'>Figma, Adobe XD, Adobe Illustrator, Adobe Photoshop, Adobe After Effects and Corel Draw.</div>
          </div>
          <div className='skill-section nunito-sans'>
            <div className='skill-top-image'><img src='skills/frontend.png' height="50%" width="100%" alt='Icon for Frontend Development' /></div>
            <div className='skill-title text-light-gray'>Frontend Development</div>
            <div className='skills text-medium-gray'>HTML, CSS, Javascript, Bootstrap, SASS, React and Flutter.</div>
          </div>
          <div className='skill-section nunito-sans'>
            <div className='skill-top-image'><img src='skills/backend.png' height="50%" width="100%" alt='Icon for Backend Development' /></div>
            <div className='skill-title text-light-gray'>Backend Development</div>
            <div className='skills text-medium-gray'>Python, C++, Java, PHP, Flask, Databases, Data Structures and Algorithms.</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default About