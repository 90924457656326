import React from 'react'
import Hero from '../hero/hero';
import Uxdesign from '../uxdesign/uxdesign';
import Graphicdesign from '../graphicdesign/graphicdesign';
import Contact from '../contact/contact';

const Home = () => {
  return (
    <React.Fragment>
        <Hero />
        <Uxdesign />
        <Graphicdesign />
        <Contact />
    </React.Fragment>
  )
}

export default Home