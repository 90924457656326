import React from 'react';
import {casestudies} from '../../data/casestudies';
import './casestudy.css';

function caseStudyMapper(project_title){
    return casestudies[project_title];
}

const CaseStudy = ({title}) => {
  let project = caseStudyMapper(title);
  
  const userTesting = project['user-testing']['user-reviews'].map( (review, number) => {return(
    <div key={number} className='user-testing' style={{backgroundColor: project['background-color']}}>
      <div className='user-testing-text nunito-sans text-darkest-gray'>
        {review}
      </div>
    </div>
    )});

  const impacts = project['impact']['impacts'].map( (impact, number) => {return(
    <div key={number} className='impact' style={{backgroundColor: project['background-color']}}>
      <div className='impact-number nunito-sans text-darkest-gray'>
        {impact['number']}
      </div>
      <div className='impact-text nunito-sans text-darkest-gray'>
        {impact['text']}
      </div>
    </div>
    )});

  const userProblems = project['user-problem']['user-problems'].map(
    (problem, number) => {
      return(
        <div key={number} className='user-problem' style={{backgroundColor: project['background-color']}}>
          <div className='user-problem-text nunito-sans text-darkest-gray'>
            {problem}
          </div>
        </div>
      )
    }
  );
  
  const lowfidelityimages = project['low-fidelity']['images'].map(
    (lowfi, number) => {
      return(
        <div key={number} className='header-image' style={{ backgroundColor : project['background-color'] }}><img src={lowfi} alt="low fidelity design" height="90%" width="70%" /></div>
      )
    }
  );
  
  const highfidelityimages = project['high-fidelity']['images'].map(
    (highfi, number) => {
      return(
        <div key={number} className='header-image' style={{ backgroundColor : project['background-color'] }}><img src={highfi.url} alt="high fidelity design" height={highfi.height} width={highfi.width} /></div>
      )
    }
  );


  window.scrollTo(0,0);
  return (
    <React.Fragment>
        <div className='breadcrumbs'>
          <div className='breadcrumb-previous nunito-sans text-medium-gray'><a href="/">Home</a>&nbsp;/&nbsp;</div>
          <div className='breadcrumb-active nunito-sans text-golden-yellow'>{project.title}</div>
        </div>
        <div className='header'>
          <div className='title-text-case clash text-light-gray'>{project.title}</div>
          <div className='title-desc text-medium-gray nunito-sans'>{project.description}</div>
          <div className='header-image' style={{ backgroundColor : project['background-color'] }}><img src={project['header-image']} alt="Header" height="100%" width="95%"/></div>
        </div>
        {/* Background of Study */}
        <div className='background-container'>
          <div className='title-text clash text-light-gray'>Background</div>
          <div className='background-grid'>
            <div className='background-left'>
            <div className='about text-light-gray nunito-sans'>About</div>
              <div className='background-about text-medium-gray nunito-sans'>{project.background['about']}</div>
            </div>
            <div className='background-right'>
              <div className='role text-light-gray nunito-sans'>Role</div>
              <div className='background-my-role text-medium-gray nunito-sans'>{project.background['my-role']}</div>
              <div className='timeline text-light-gray nunito-sans'>Duration</div>
              <div className='background-timeline text-medium-gray nunito-sans'>{project.background['time-period']}</div>
            </div>
          </div>
        </div>

        {/* User Problems */}
        <div className='user-problems-container'>
          <div className='title-text clash text-light-gray'>User Problems</div>
          <div className='top-content text-medium-gray nunito-sans'>{project['user-problem']['top-content']}</div>
          <div className='user-problems-grid'>
            {/* Loop starts here */}
            {userProblems}
            {/* Loop ends here */}
          </div>
        </div>

        {/* Solving the Problem */}
        <div className='solving-problem'>
          <div className='title-text clash text-light-gray'>Solving the Problems</div>
          <div className='top-content text-medium-gray nunito-sans'>{project['low-fidelity']['top-content']}</div>
          {lowfidelityimages}
        </div>

        {/* User Testing */}
        <div className='user-testing-container'>
          <div className='title-text clash text-light-gray'>Testing the Solution</div>
          <div className='top-content text-medium-gray nunito-sans'>{project['user-testing']['top-content']}</div>
          <div className='user-testing-grid'>
            {/* Loop starts here */}
            {userTesting}
            {/* Loop ends here */}
          </div>
        </div>

        {/* High Fidelity */}
        <div className='high-fidelity'>
          <div className='title-text clash text-light-gray'>The Final Designs</div>
          <div className='top-content text-medium-gray nunito-sans'>{project['high-fidelity']['top-content']}</div>
          {highfidelityimages}
          {/* <div className='header-image' style={{ backgroundColor : project['background-color'] }}><img src={project['high-fidelity']['images'][0]} height="100%" width="95%" /></div>
          <div className='header-image' style={{ backgroundColor : project['background-color'] }}><img src={project['high-fidelity']['images'][1]} height="100%" width="95%" /></div> */}
        </div>
        
        {/* Impact */}
        <div className='impact-container'>
          <div className='title-text clash text-light-gray'>Impact</div>
          <div className='top-content text-medium-gray nunito-sans'>{project['impact']['top-content']}</div>
          <div className='impact-grid'>
            {/* Loop starts here */}
            {impacts}
            {/* Loop ends here */}
          </div>
        </div>

        {/* Takeaways */}
        <div className='takeaway'>
          <div className='title-text clash text-light-gray'>Takeaways</div>
          <div className='top-content text-medium-gray nunito-sans'>{project['takeaways']}</div>
        </div>

        {/* Back to Home */}
        <div className='back-to-home cursor-pointer'>
        <a href="/"><button className='back-home nunito-sans'>Back to Home</button></a>
        </div>

    </React.Fragment>
  )
}

export default CaseStudy;