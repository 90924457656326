export const casestudies = {
    "dhyana-meditation-app" :{
        "title" : "Dhyana - Meditation App",
        "background-color" : "#FFDE9A",
        "description" : "Designed an application which will help users relax with meditation, no matter where they would be.",
        "header-image" : "ux/meditation_app/flow1.png",
        "background" : {
            "about" : "I was a part of Meditation and Wellness Club at my college, where I met people who were reluctant to practice meditation. They mentioned challenges - not able to take out time from their schedule, some of them worked two jobs to make ends meet. They wanted a way in which they could imbibe meditation into their lifestyle without hampering their busy schedules.",
            "my-role" : "As a personal project, I worked on User Research, Visual Design, User Testing and currently developing the application for real users to use.",
            "time-period" : "March 2022 - July 2022"
        },
        "user-problem" : {
            "top-content" : "After having a discussion with people who were reluctant to practice meditation, I could identify three major problems for which these users were looking for a solution.",
            "user-problems" : [
                "I spend 2 hours travelling in public transport. That is the only time when I even take up my phone.",
                "I cannot travel or join online Zoom sessions as I work two full-time jobs to make ends meet.",
                "The internet consumed by online sessions is overwhelming for my budget."
            ]
        },
        "low-fidelity" : {
            "top-content" : "To solve the user problems, I thought of an mobile application, which would enable users to meditate and relax anywhere. It would have sessions ranging from 5 minutes to 30 minutes depending on the user's schedule.",
           "images" : [
            "ux/meditation_app/lowfi.png"
           ]
        },
        "user-testing" : {
            "top-content" : "To test out if the solution could address the challenges the user faced, I conducted a remote study via Google Meet. Asked users to complete basic tasks on the app and asked about their experience and the problems they faced while completing the tasks. These are some of the observations noted.",
            "user-reviews" : [
                "I'm not sure why the app needs me to login, if I just have to do meditation.",
                "I got an urgent task to do when I was meditating, and I couldn't figure out how to stop the meditation session on the app.",
                "It would have been better if I could just jot down my feelings after sessions and see my improvement over time."
            ]
        },
        "high-fidelity" : {
            "top-content" : "After working on the feedback and improving the experience, I came up with this finalized prototype, which could solve the users' problems. There are more aspects for improvement, which I decided to inculcate in the later revisions post development.",
            "images" : [
                {
                    "url":"ux/meditation_app/meditation_image_1.png",
                    "height": "90%",
                    "width" : "70%"
                },
                {
                    "url":"ux/meditation_app/meditation_image_2.png",
                    "height": "90%",
                    "width" : "70%"
                },
                {
                    "url":"ux/meditation_app/meditation_image_3.png",
                    "height": "90%",
                    "width" : "70%"
                },
                {
                    "url":"ux/meditation_app/flow1.png",
                    "height": "100%",
                    "width" : "95%"
                },
                {
                    "url":"ux/meditation_app/flow2.png",
                    "height": "100%",
                    "width" : "95%"
                },
            ]
        },
        "impact" : {
            "top-content": "We tested out our prototype in one of the recent meditation camps. Here are some interesting results from the test.",
            "impacts": [
                {
                    "number": "95%",
                    "text": "Found it helpful that custom session duration could be set, so that meditation can be included in their busy schedules."
                },
                {
                    "number": "10%",
                    "text": "People had to use the End Session flow, due to some unavoidable and urgent reasons and they were able to complete the flow."
                },
                {
                    "number": "90%",
                    "text": "Found the feedback flow intuitive and easy, they could understand and interact appropriately."
                },
            ]
        },
        "takeaways" : "After working on the feedback and improving the experience, I came up with this finalized prototype, which could solve the users' problems. There are more aspects for improvement, which I decided to inculcate in the later revisions post development"
    },

    "classroom-app" :{
        "title" : "Classroom - Concept App",
        "background-color" : "#A7CDFF",
        "description" : "An app for students of schools and colleges from which they can join lectures online, look up for classes for the day and keep a track of tasks and assignments.",
        "header-image" : "ux/classroom_app/flow1.png",
        "background" : {
            "about" : "In my final year of Engineering, online lectures became 'the only option' due to lockdown. Having faced many issues, I decided to study this in detail, by going through the Design Process and developing a User Friendly Solution.",
            "my-role" : "Since this is a personal project, I worked on User Research, Brainstorming Ideas, Making and evaluating Wireframes, Making a working prototype and testing it with real users for their feedback.",
            "time-period" : "October 2021 - December 2021"
        },
        "user-problem" : {
            "top-content" : "I conducted user interviews with 3 college students and 3 school students, from different backgrounds. After interacting with them, I understood their daily schedule for attending classes and their pain points:",
            "user-problems" : [
                "I need to have 2-3 apps just to attend lectures - one to check timetable, one to join lecture and one to communicate.",
                "All notes are shared on Instant Messaging Apps, and when I open it, I get distracted due to other chats.",
                "I have to submit my assignments separately via mail, or download some other app just to upload and send them to faculties."
            ]
        },
        "low-fidelity" : {
            "top-content" : "Students need to locate which class is scheduled for the current day and get the links to join those classrooms. Also, they need a consolidated notes section and easy way to track deadlines and submit assignments.",
           "images" : [
            "ux/classroom_app/lowfi1.png",
            "ux/classroom_app/lowfi2.png"
           ]
        },
        "user-testing" : {
            "top-content" : "I observed how the students interacted with the prototype, trying to accomplish their tasks. In the end, I asked them some questions regarding usability and visual appeal. I also enquired about any pain points. Here are some excerpts from the responses:",
            "user-reviews" : [
                "It would be better if I could know which class to join now, when I open the app. Too many 'Join' buttons are making me confused.",
                "Looks great! I can easily move through all important sections with simple swipe gestures. It will surely increase my productivity!",
                "The icons look very intimidating, and does not easily tell what the menu is about. There is option to save draft, but nowhere to view saved drafts."
            ]
        },
        "high-fidelity" : {
            "top-content" : "After working on the feedback and improving the experience, I came up with this finalized prototype, which could solve the users' problems. There are more aspects for improvement, which I decided to inculcate in the later revisions post development.",
            "images" : [
                {
                    "url":"ux/classroom_app/classroom_app_1.png",
                    "height": "90%",
                    "width" : "60%"
                },
                {
                    "url":"ux/classroom_app/classroom_app_2.png",
                    "height": "90%",
                    "width" : "60%"
                },
                {
                    "url":"ux/classroom_app/classroom_app_3.png",
                    "height": "90%",
                    "width" : "60%"
                },
                {
                    "url":"ux/classroom_app/flow1.png",
                    "height": "100%",
                    "width" : "95%"
                },
            ]
        },
        "impact" : {
            "top-content": "During the usability study, we compared the time taken by the students to do tasks. Comparing with the current flow, we could find some interesting impacts which could solve the problems which we had at the start.",
            "impacts": [
                {
                    "number": "60%",
                    "text": "Lower time taken to open notes in prototype than in current flow."
                },
                {
                    "number": "80%",
                    "text": "Decrease in distractions faced by students using the prototype, than in original flow."
                },
                {
                    "number": "90%",
                    "text": "Lower time taken to open online lectures as compared to current methods."
                }          
            ]
        },
        "takeaways" : "This is my first UX Project, where I worked with real students. When interacting with them, I came across pain points in current scenario. I put myself in the shoes of a student, and came up with ideas which can be beneficial for students. Having real students try it out validated my ideas. I learnt how to do UX Research, Identifying Pain Points, Generating Ideas, Evaluating and Bringing them to life. Also, testing it with real users regularly, so as to identify new pain points and figuring out solutions to improve."
    },
    
}