import React from 'react'
import './graphicdesign.css';


const Graphicdesign = () => {
  return (
    <React.Fragment>
        <div className='title-text text-light-gray clash' id="graphicdesign">Graphic Design Works</div>
        <div className='projects-grid'>
            <div className='project-grid-item'><img src='graphic/armaanmalik.jpg' alt="Armaan Malik Poster for Alegria 2020" height="100%" width="100%" /></div>
            <div className='project-grid-item'><img src='graphic/diegomiranda.jpg' alt="Diego Miranda Poster for Alegria 2020" height="100%" width="100%" /></div>
            <div className='project-grid-item'><img src='graphic/Dog Superhero KGF.jpg' height="100%" width="100%" alt="Superhero Dog Composite" /></div>
            <div className='project-grid-item'><img src='graphic/dog.jpg' height="100%" width="100%" alt="Dog in Space Composite" /></div>
            <div className='project-grid-item'><img src='graphic/gaush.jpg' height="100%" width="100%" alt="Gaush - Tshirt Design" /></div>
            <div className='project-grid-item'><img src='graphic/Quest.jpg' height="100%" width="100%" alt="Man with a torch looking at a secret opening from a cave" /></div>
        </div>
    </React.Fragment>
  )
}

export default Graphicdesign;