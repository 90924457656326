import React from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import Home from './components/home/home';
import './App.css';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import ScrollToTop from 'react-scroll-to-top';
import About from './components/about/about';
import CaseStudy from './components/casestudy/casestudy';

const App = () => {
  
  return (
    <React.Fragment>
      <BrowserRouter>
      <Navbar />
      <ScrollToTop smooth />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />} />
        <Route exact path='/:name' element={ <RouteMapper /> } />
      </Routes>
      <Footer />
      </BrowserRouter>
    </React.Fragment>
  )
}

function RouteMapper() {
  let { name } = useParams();
  return(
    <CaseStudy title={name} />
  )
}

export default App;