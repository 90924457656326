import {React, useState, useEffect} from 'react';
import { Link as ScrollableLink } from 'react-scroll';
import './hero.css';


const Hero = () => {
  const list_of_adjectives = ['friendly', 'usable', 'handy', 'amazing'];
  const [textIndex, setTextIndex] = useState(0);
  const list_of_colors = ['stage-one', 'stage-two', 'stage-three', 'stage-four', 'stage-three', 'stage-two']
  const [textColorIndex, setTextColorIndex] = useState(0);
  useEffect(() => {
    
    // Changes the word
    setTimeout(() => {
      if(textIndex === 3){
        setTextIndex(0);
      }
      else{
        setTextIndex(textIndex + 1); 
      }
    }, 1000);

    // Changes the color
    setTimeout(() => {
      if(textColorIndex === 5){
        setTextColorIndex(0);
      }
      else{
        setTextColorIndex(textColorIndex + 1);
      }
    }, 1000);
  }, [textIndex, textColorIndex]);
  return (
    <div className='hero'>
        <div className='hero-text clash'>
            <span className='hero-text-main text-light-gray'>Designing</span>
            <span className={`hero-yellow-text hero-text-main ${list_of_colors[textColorIndex]} hero-yellow-text-animate`}>
              <span className='text-darker-gray text-animate'>{list_of_adjectives[textIndex]}</span>
            </span>
            <span className='hero-text-main text-light-gray'>products</span>
        </div>
        <div className='hero-content nunito-sans text-medium-gray'>
          Hi! I'm Romax, a user experience freelance designer based in India. I help businesses to turn their ideas into great products their customers love to interact with.
        </div>
        <div className='hero-cta'>
        <ScrollableLink smooth={true} to="uxdesign"><button className={`${list_of_colors[textColorIndex]} text-darker-gray no-border nunito-sans`}>My Projects</button></ScrollableLink>
        <ScrollableLink smooth={true} to="contact"><button className={`darker-gray text-${list_of_colors[textColorIndex]} ${list_of_colors[textColorIndex]}-border nunito-sans`}>Contact Me</button></ScrollableLink>
        </div>

    </div>
  )
}

export default Hero;