import React from 'react';
import './footer.css';
import { FaLinkedin, FaInstagramSquare, FaDribbbleSquare, FaGithubSquare } from "react-icons/fa";

const Footer = () => {
    const year = new Date().getFullYear();
  return (
    <div className='footer-container'>
        <div className='footer-text nunito-sans'>
            <div className='footer-left'>
              &copy; {year}. All Rights Reserved. Designed by Romax Rajeev.
            </div>
            <div className='footer-right'>
              <a href="https://www.instagram.com/desmax_designs/" target="_blank" rel="noreferrer"><FaInstagramSquare /></a>
              <a href="https://www.linkedin.com/in/romax-rajeev/" target="_blank" rel="noreferrer"><FaLinkedin /></a>
              <a href="https://dribbble.com/desmaxdesigns" target="_blank" rel="noreferrer"><FaDribbbleSquare /></a>
              <a href="https://github.com/romaxrajeev" target="_blank" rel="noreferrer"><FaGithubSquare /></a>
            </div>
        </div>
    </div>
  )
}

export default Footer