import React from 'react';
import './uxdesign.css';
import { uxprojects } from '../../data/uxdesign';
import {Link} from 'react-router-dom';

const Uxdesign = () => {

  const uxProjects = uxprojects.map( (project) => {
    if(project.status === 'Read More'){
      return(
        <Link to={`/${project.title}`} key={project.id} >
          <div className='ux-project' style={{backgroundColor: project['background-color']}}>
            <div className='ux-project-details' style={{order: project['order-text']}}>
              <div className='ux-project-title clash'>{project.name}</div>
              <div className='ux-project-desc nunito-sans'>{project.desc}</div>
              <div className='ux-read-more nunito-sans cursor-pointer'>{project.status}</div>
            </div>
            <div className='ux-project-image' style={{order: project['order-image']}}>
              <img src={project['main-image']} alt={project['image-alt']} height={project.height} width={project.width} />
            </div>
          </div>
        </Link>
      );
    }
    else{
      return(
        <Link to="" key={project.id} >
          <div className='ux-project' style={{backgroundColor: project['background-color']}}>
            <div className='ux-project-details' style={{order: project['order-text']}}>
              <div className='ux-project-title clash'>{project.name}</div>
              <div className='ux-project-desc nunito-sans'>{project.desc}</div>
              <div className='ux-read-more nunito-sans cursor-pointer'>{project.status}</div>
            </div>
            <div className='ux-project-image' style={{order: project['order-image']}}>
              <img src={project['main-image']} alt={project['image-alt']} height={project.height} width={project.width} />
            </div>
          </div>
        </Link>
      );
    }
  });

  return (
    <React.Fragment>
        <div className='title-text text-light-gray clash' id="uxdesign">User Experience Projects</div>
        <div className='ux-container'>
          {/* Loop starts here */}
            { uxProjects }
          {/* loop ends here */}
        </div>
    </React.Fragment>
  )
}

export default Uxdesign;